<template>
    <b-col sm="12" class="p-0 m-0">
        <b-card>
            <b-card-title>Consultez les fichiers S3</b-card-title>
            <b-card-body>
                <b-form>
                    <b-form-group
                            label="ID du capteur :"
                            v-if="sensorId"
                            label-for="sensorId"
                    >
                        <b-form-input
                                id="sensorId"
                                v-model="sensorId"
                                type="text"
                                placeholder="Entrez l'ID du capteur"
                        />
                    </b-form-group>

                    <b-form-group
                            label="Version du capteur :"
                            v-if="sensorVersion"
                            label-for="sensorVersion"
                    >
                        <b-form-select
                                id="sensorVersion"
                                v-model="sensorVersion"
                                :options="versionOptions"
                        />
                    </b-form-group>

                    <b-button
                            variant="primary"
                            @click="fetchFiles"
                            :disabled="isLoading"
                    >
                        <span v-if="!isLoading">Voir les fichiers</span>
                        <b-spinner small v-else label="Chargement..." />
                    </b-button>
                </b-form>

                <b-alert
                        variant="danger"
                        v-if="error"
                        dismissible
                        class="mt-3"
                >
                    {{ error }}
                </b-alert>

                <div v-if="files.length" class="mt-4">
                    <h5>Fichiers disponibles :</h5>
                    <b-list-group>
                        <b-list-group-item
                                v-for="file in paginatedFiles"
                                :key="file.Key"
                                class="d-flex justify-content-between align-items-center"
                        >
                            <!-- Nom du fichier et date -->
                            <div>
                                <span>{{ file.Key }}</span><br />
                                <small class="text-muted">{{ formatDate(file.LastModifiedISO) }}</small>
                            </div>
                            <!-- Boutons alignés à droite -->
                            <div class="ml-auto">
                                <b-button
                                        size="sm"
                                        class="mr-2 bg-white text-primary"
                                        @click="() => {
                            callModal();
                            previewFile(file.Key);
                        }"
                                        :disabled="isPreviewLoading"
                                >
                                    <b-spinner v-if="isPreviewLoading && file.Key === chosenKey" small></b-spinner>
                                    Prévisualiser
                                </b-button>
                                <b-button
                                        variant="primary"
                                        size="sm"
                                        @click="downloadFile(file.Key)"
                                >
                                    Télécharger
                                </b-button>
                            </div>
                        </b-list-group-item>
                    </b-list-group>

                    <!-- Pagination -->
                    <b-pagination
                            v-model="currentPage"
                            :total-rows="files.length"
                            :per-page="itemsPerPage"
                            align="center"
                            class="mt-3"
                    ></b-pagination>
                </div>
                <b-modal
                        id="preview-modal"
                        title="Prévisualisation du fichier"
                        @hide="clearPreview"
                        size="lg"
                        scrollable
                >
                    <b-spinner v-if="isPreviewLoading" label="Chargement des Données" />

                    <div v-for="(item, index) in previewContent" :key="index" class="mb-4">
                        <b-card>
                            <!-- Date et Identifiant -->
                            <b-card-title class="d-flex align-items-center">
                                <b-icon icon="clock-fill" class="mr-2"></b-icon>
                                {{ formatDate(item.Date) }}
                                <b-badge variant="primary" class="ml-auto">{{ item.ID }}</b-badge>
                            </b-card-title>

                            <!-- Données GPS -->
                            <b-card-text>
                                <b-icon icon="geo-alt-fill" class="mr-2"></b-icon>
                                <strong>Position :</strong>
                                <a
                                        :href="`https://www.google.com/maps?q=${item.Latitude},${item.Longitude}`"
                                        target="_blank"
                                        class="text-decoration-none"
                                >
                                    {{ item.Latitude }}, {{ item.Longitude }}
                                </a>
                            </b-card-text>

                            <!-- Données Environnementales -->
                            <b-card-text>
                                <ul class="list-unstyled">
                                    <li>
                                        <b-icon icon="cloud-fill" class="mr-2"></b-icon>
                                        <strong>Pression :</strong> {{ item.Pressure }} hPa
                                    </li>
                                    <li>
                                        <b-icon icon="thermometer-half" class="mr-2"></b-icon>
                                        <strong>Température :</strong> {{ item.Temperature }} °C
                                    </li>
                                    <li>
                                        <b-icon icon="droplet-fill" class="mr-2"></b-icon>
                                        <strong>Humidité :</strong> {{ item.Humidity }} %
                                    </li>
                                </ul>
                            </b-card-text>

                            <!-- Trame de Données -->
                            <b-card-text>
                                <h5>Trame des Données</h5>
                                <div style="overflow-x: auto;">
                                    <b-table
                                            :items="formatTrame(item.data)"
                                            :fields="generateTrameHeaders()"
                                            striped
                                            hover
                                            small
                                            responsive="sm"
                                            class="mt-3"
                                            style="min-width: 1500px;"/>
                                </div>
                            </b-card-text>
                        </b-card>
                    </div>
                </b-modal>
            </b-card-body>
        </b-card>
    </b-col>
</template>

<script>
import AWS from "aws-sdk";
const pako = require('pako');
const fzstd = require('fzstd');

export default {
    name: "S3FileViewer",
    props: {
        actualSensor: Object,
    },
    data() {
        return {
            sensorId: this.actualSensor.serialNumber || "",
            sensorVersion: this.actualSensor.sensorVersion || "v2",
            versionOptions: [
                { value: "v1", text: "v1" },
                { value: "v2", text: "v2" },
                { value: "v3", text: "v3" },
            ],
            files: [],
            error: null,
            isLoading: false,
            previewContent: null,
            detailedData: null,
            isPreviewLoading: false,
            chosenKey: null,
            currentPage: 1,
            itemsPerPage: 10,
        };
    },
    methods: {
        getBucketAndPrefix() {
            const type = this.actualSensor.type.toLowerCase();
            const typeMapping = {
                pollution: { v1: "LC1", v2: "LC2" },
                pollen: { v2: "BN3", v3: "BN3" },
            };

            const bucket = this.sensorVersion === 'v3' || (type === 'pollution' && this.sensorVersion === 'v2') ? "laml-raw-data-storage" : "lifyair-iot-core-logs";
            const prefix = this.sensorVersion === 'v3' || (type === 'pollution' && this.sensorVersion === 'v2')
                    ? `${type}/${typeMapping[type][this.sensorVersion]}/${this.sensorId}/` : `${type === 'pollen' ? 'beenose' : 'loac-auto'}/${this.sensorId}/`;
            return { bucket, prefix };
        },
        getFileExtension(filename) {
            return filename.split('.').pop();
        },
        async fetchFiles() {
            try {
                this.error = null;
                this.files = [];
                this.isLoading = true;

                if (!this.sensorId) {
                    this.error = "Veuillez entrer un ID de capteur.";
                    this.isLoading = false;
                    return;
                }

                const { bucket, prefix } = this.getBucketAndPrefix();
                const s3 = new AWS.S3();
                let isTruncated = true;
                let continuationToken = null;

                while (isTruncated) {
                    const params = {
                        Bucket: bucket,
                        Prefix: prefix,
                        ContinuationToken: continuationToken, // Pour récupérer la page suivante
                    };

                    const data = await s3.listObjectsV2(params).promise();

                    if (data.Contents && data.Contents.length > 0) {
                        // Ajoute les fichiers à la liste et trie par date décroissante
                        this.files = [
                            ...this.files,
                            ...data.Contents.map((file) => ({
                                ...file,
                                LastModifiedISO: file.LastModified
                                    ? new Date(file.LastModified).toISOString()
                                    : null,
                            })),
                        ];
                    }

                    isTruncated = data.IsTruncated; // Indique si d'autres fichiers doivent être récupérés
                    continuationToken = data.NextContinuationToken; // Récupère le token pour la prochaine page
                }

                // Trier les fichiers par date décroissante
                this.files.sort((a, b) => new Date(b.LastModifiedISO) - new Date(a.LastModifiedISO));
            } catch (e) {
                console.error(e);
                this.error = "Une erreur s'est produite lors de la récupération des fichiers.";
            } finally {
                this.isLoading = false;
            }
        },
        async downloadFile(key) {
            try {
                const { bucket } = this.getBucketAndPrefix();
                const s3 = new AWS.S3();
                const params = {
                    Bucket: bucket,
                    Key: key,
                };

                const data = await s3.getObject(params).promise();
                const blob = new Blob([data.Body], { type: "application/gzip" });

                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = key.split("/").pop();
                link.click();
            } catch (e) {
                console.error(e);
                this.error = "Erreur lors du téléchargement du fichier.";
            }
        },
        callModal(){
            this.$bvModal.show('preview-modal');
        },
        async previewFile(key) {
            this.callModal();
            this.isPreviewLoading = true;
            this.chosenKey = key;

            try {
                const { bucket } = this.getBucketAndPrefix();
                const s3 = new AWS.S3();
                const params = {
                    Bucket: bucket,
                    Key: key,
                };

                // Récupérer le fichier depuis S3
                const data = await s3.getObject(params).promise();

                // Détecter le type de fichier
                const extension = this.getFileExtension(key);

                let decompressedData;
                if (extension === 'gz') {
                    // Décompresser GZ
                    decompressedData = pako.inflate(data.Body, { to: 'string' });
                } else if (extension === 'zst') {
                    // Décompresser ZST avec fzstd
                    const compressed = new Uint8Array(data.Body); // Convertir en Uint8Array
                    const decompressed = fzstd.decompress(compressed); // Décompresser
                    decompressedData = new TextDecoder('utf-8').decode(decompressed); // Convertir en texte
                } else {
                    throw new Error('Format de fichier non pris en charge.');
                }

                // Vérifier si le contenu est JSON
                const jsonData = JSON.parse(decompressedData);

                // Vérifier si c'est un tableau JSON
                if (Array.isArray(jsonData)) {
                    this.previewContent = jsonData.map((item) => ({
                        Date: item.date,
                        ID: item.ID,
                        Pressure: item.P,
                        Temperature: item.T,
                        Humidity: item.U,
                        Latitude: item.lat,
                        Longitude: item.lon,
                        version: item.PR,
                        gsm: item.GSM,
                        data: item.trame,
                    }));
                } else {
                    this.previewContent = 'Format JSON inattendu.';
                }
            } catch (e) {
                console.error(e);
                this.error = 'Erreur lors de la prévisualisation du fichier.';
            } finally {
                this.isPreviewLoading = false;
                this.chosenKey = null;
            }
        },
        generateTrameHeaders() {
            const type = this.actualSensor.type.toLowerCase();

            // Génère les en-têtes de colonnes pour la trame
            let header = [
                    { key: "t_int", label: "T Int" },
                    { key: "offset_voie15", label: "Offset Voie 15" },
                    { key: "gamme1_voie15", label: "Gamme 1 Voie 15" },
                    { key: "gamme2_voie15", label: "Gamme 2 Voie 15" },
                    { key: "gamme3_voie15", label: "Gamme 3 Voie 15" },
                    { key: "gamme4_voie15", label: "Gamme 4 Voie 15" },
                    { key: "gamme5_voie15", label: "Gamme 5 Voie 15" },
                    { key: "gamme6_voie15", label: "Gamme 6 Voie 15" },
                    { key: "gamme7_voie15", label: "Gamme 7 Voie 15" },
                    { key: "gamme8_voie15", label: "Gamme 8 Voie 15" },
                    { key: "gamme9_voie15", label: "Gamme 9 Voie 15" },
                    { key: "gamme10_voie15", label: "Gamme 10 Voie 15" },
                    { key: "gamme11_voie15", label: "Gamme 11 Voie 15" },
                    { key: "gamme12_voie15", label: "Gamme 12 Voie 15" },
                    { key: "gamme13_voie15", label: "Gamme 13 Voie 15" },
                    { key: "gamme14_voie15", label: "Gamme 14 Voie 15" },
                    { key: "gamme15_voie15", label: "Gamme 15 Voie 15" },
                    { key: "gamme16_voie15", label: "Gamme 16 Voie 15" },
                    { key: "gamme17_voie15", label: "Gamme 17 Voie 15" },
                    { key: "gamme18_voie15", label: "Gamme 18 Voie 15" },
                    { key: "gamme19_voie15", label: "Gamme 19 Voie 15" },
                    { key: "offset_voie60", label: "Offset Voie 60" },
                    { key: "gamme1_voie60", label: "Gamme 1 Voie 60" },
                    { key: "gamme2_voie60", label: "Gamme 2 Voie 60" },
                    { key: "gamme3_voie60", label: "Gamme 3 Voie 60" },
                    { key: "gamme4_voie60", label: "Gamme 4 Voie 60" },
                    { key: "gamme5_voie60", label: "Gamme 5 Voie 60" },
                    { key: "gamme6_voie60", label: "Gamme 6 Voie 60" },
                    { key: "gamme7_voie60", label: "Gamme 7 Voie 60" },
                    { key: "gamme8_voie60", label: "Gamme 8 Voie 60" },
                    { key: "gamme9_voie60", label: "Gamme 9 Voie 60" },
                    { key: "gamme10_voie60", label: "Gamme 10 Voie 60" },
                    { key: "gamme11_voie60", label: "Gamme 11 Voie 60" },
                    { key: "gamme12_voie60", label: "Gamme 12 Voie 60" },
                    { key: "gamme13_voie60", label: "Gamme 13 Voie 60" },
                    { key: "gamme14_voie60", label: "Gamme 14 Voie 60" },
                    { key: "gamme15_voie60", label: "Gamme 15 Voie 60" },
                    { key: "gamme16_voie60", label: "Gamme 16 Voie 60" },
                    { key: "gamme17_voie60", label: "Gamme 17 Voie 60" },
                    { key: "gamme18_voie60", label: "Gamme 18 Voie 60" },
                    { key: "gamme19_voie60", label: "Gamme 19 Voie 60" },
                    { key: "offset_voie125", label: "Offset Voie 125" },
                    { key: "gamme1_voie125", label: "Gamme 1 Voie 125" },
                    { key: "gamme2_voie125", label: "Gamme 2 Voie 125" },
                    { key: "gamme3_voie125", label: "Gamme 3 Voie 125" },
                    { key: "gamme4_voie125", label: "Gamme 4 Voie 125" },
                    { key: "gamme5_voie125", label: "Gamme 5 Voie 125" },
                    { key: "gamme6_voie125", label: "Gamme 6 Voie 125" },
                    { key: "gamme7_voie125", label: "Gamme 7 Voie 125" },
                    { key: "gamme8_voie125", label: "Gamme 8 Voie 125" },
                    { key: "gamme9_voie125", label: "Gamme 9 Voie 125" },
                    { key: "gamme10_voie125", label: "Gamme 10 Voie 125" },
                    { key: "gamme11_voie125", label: "Gamme 11 Voie 125" },
                    { key: "gamme12_voie125", label: "Gamme 12 Voie 125" },
                    { key: "gamme13_voie125", label: "Gamme 13 Voie 125" },
                    { key: "gamme14_voie125", label: "Gamme 14 Voie 125" },
                    { key: "gamme15_voie125", label: "Gamme 15 Voie 125" },
                    { key: "gamme16_voie125", label: "Gamme 16 Voie 125" },
                    { key: "gamme17_voie125", label: "Gamme 17 Voie 125" },
                    { key: "gamme18_voie125", label: "Gamme 18 Voie 125" },
                    { key: "gamme19_voie125", label: "Gamme 19 Voie 125" },
                    { key: "offset_voie160", label: "Offset Voie 160" },
                    { key: "gamme1_voie160", label: "Gamme 1 Voie 160" },
                    { key: "gamme2_voie160", label: "Gamme 2 Voie 160" },
                    { key: "gamme3_voie160", label: "Gamme 3 Voie 160" },
                    { key: "gamme4_voie160", label: "Gamme 4 Voie 160" },
                    { key: "gamme5_voie160", label: "Gamme 5 Voie 160" },
                    { key: "gamme6_voie160", label: "Gamme 6 Voie 160" },
                    { key: "gamme7_voie160", label: "Gamme 7 Voie 160" },
                    { key: "gamme8_voie160", label: "Gamme 8 Voie 160" },
                    { key: "gamme9_voie160", label: "Gamme 9 Voie 160" },
                    { key: "gamme10_voie160", label: "Gamme 10 Voie 160" },
                    { key: "gamme11_voie160", label: "Gamme 11 Voie 160" },
                    { key: "gamme12_voie160", label: "Gamme 12 Voie 160" },
                    { key: "gamme13_voie160", label: "Gamme 13 Voie 160" },
                    { key: "gamme14_voie160", label: "Gamme 14 Voie 160" },
                    { key: "gamme15_voie160", label: "Gamme 15 Voie 160" },
                    { key: "gamme16_voie160", label: "Gamme 16 Voie 160" },
                    { key: "gamme17_voie160", label: "Gamme 17 Voie 160" },
                    { key: "gamme18_voie160", label: "Gamme 18 Voie 160" },
                    { key: "gamme19_voie160", label: "Gamme 19 Voie 160" },
                ];

            if (type === 'pollution') {
                header.push(
                    { key: "bruit_voie15", label: "Bruit Voie 15" },
                    { key: "bruit_voie60", label: "Bruit Voie 60" },
                    { key: "bruit_voie125", label: "Bruit Voie 125" },
                    { key: "bruit_voie160", label: "Bruit Voie 160" }
                )
            }

            return header
        },
        formatTrame(trame) {
            const keys = this.generateTrameHeaders().map((header) => header.key);
            const formattedData = {};
            keys.forEach((key, index) => {
                formattedData[key] = trame[index];
            });
            return [formattedData];
        },
        formatDate(date) {
            const options = {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                timeZone: "UTC",
            };
            return new Date(date).toLocaleDateString("fr-FR", options);
        },
        clearPreview() {
            this.previewContent = null;
        }
    },
    computed: {
        paginatedFiles() {
            const start = (this.currentPage - 1) * this.itemsPerPage;
            const end = start + this.itemsPerPage;
            return this.files.slice(start, end);
        },
    },
    async mounted() {
        if (this.sensorVersion && this.sensorId) {
            await this.fetchFiles();
        }
    },
};
</script>

<style scoped>
/* Assure que les colonnes ont suffisamment d'espace */
.b-table th,
.b-table td {
    white-space: nowrap; /* Évite les retours à la ligne */
    text-align: center; /* Centrer le contenu */
}
</style>
